































































import Vue from 'vue'
import Component from 'vue-class-component'
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import {Prop} from 'vue-property-decorator';
import SygniCard from "@/components/layout/SygniCard.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniSocialsButton from "@/components/buttons/SygniSocialsButton.vue";
import {ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {mapGetters} from "vuex";
import {GlobalSelects} from "@/modules/shared/dictionary-store/types";

Component.registerHooks(['validations'])
@Component({
  components: {SygniSocialsButton, UserPresentation, SygniCard, SygniRoundedButton},
  computed: {
    ...mapGetters('dictionary', {
      globalSelects: 'globalSelects'
    })
  }
})

export default class ProfilePresentation extends Vue {
  @Prop() data!: ProfileDataState;
  globalSelects!: GlobalSelects;

  get name() {
    if (this.data?.clientType === 'legal-entity') {
      return this.data?.generalData?.name
    }

    return this.data?.generalData?.firstName + ' ' + this.data?.generalData?.surname
  }

  translateCountry(countryValue: string): string {
    if(this.globalSelects.countries.length === 0) return countryValue;
    return this.globalSelects.countries.find( country => country.value === countryValue).label ?? countryValue;
  }

  mounted(){
  }
}
