var render = function () {
  var _vm$data, _vm$data$generalData;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sygni-card', {
    staticClass: "dashboard-header"
  }, [_c('div', {
    staticClass: "dashboard-header__up"
  }, [_c('div', {
    staticClass: "dashboard-header__presentation"
  }, [_c('user-presentation', {
    attrs: {
      "name": _vm.name,
      "description": _vm.data.generalData.professionalHeadline,
      "image": _vm.data.generalData.photo
    }
  }), _c('div', {
    staticClass: "dashboard-header__socials"
  }, [_c('a', {
    attrs: {
      "href": _vm.data.generalData.facebook
    }
  }, [_vm.data.generalData.facebook ? _c('sygni-socials-button', [_vm._v(" f ")]) : _vm._e()], 1), _c('a', {
    attrs: {
      "href": _vm.data.generalData.linkedIn
    }
  }, [_vm.data.generalData.linkedIn ? _c('sygni-socials-button', [_vm._v(" ln ")]) : _vm._e()], 1)])], 1), _c('img', {
    attrs: {
      "src": require("@/assets/images/dashboard-profile-background.png")
    }
  })]), _c('div', {
    staticClass: "dashboard-header__footer"
  }, [(_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$generalData = _vm$data.generalData) !== null && _vm$data$generalData !== void 0 && _vm$data$generalData.phone ? _c('div', {
    staticClass: "information-box"
  }, [_c('div', {
    staticClass: "information-box__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icons/call.svg')
    }
  })]), _c('div', {
    staticClass: "information-box__text"
  }, [_vm._v(" " + _vm._s(_vm.data.generalData.phone) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "information-box"
  }, [_c('div', {
    staticClass: "information-box__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icons/letter.svg')
    }
  })]), _c('div', {
    staticClass: "information-box__text"
  }, [_vm._v(" " + _vm._s(_vm.data.generalData.email) + " ")])]), _c('div', {
    staticClass: "information-box"
  }, [_c('div', {
    staticClass: "information-box__icon"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/icons/location.svg')
    }
  })]), _vm.data.residentialAddress.country || _vm.data.residentialAddress.city ? _c('div', {
    staticClass: "information-box__text"
  }, [_vm._v(" " + _vm._s(_vm.data.residentialAddress.city) + ", " + _vm._s(_vm.translateCountry(_vm.data.residentialAddress.country)) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "information-box"
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }