



























import Vue from 'vue'
import Component from 'vue-class-component'
import SygniCard from "@/components/layout/SygniCard.vue";
import UserPresentation from "@/components/UserPresentation.vue";
import SygniSocialsButton from "@/components/buttons/SygniSocialsButton.vue";
import ProfilePresentation from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfilePresentation.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import ProfileTransactionHistoryTable
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileTransactionHistoryTable.vue";
import ProfileInvestorsDashboard
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileInvestorsDashboard.vue";
import ProfileSideBar from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileSideBar.vue";
import {mapState} from "vuex";
import {BusinessLink, ProfileDataState} from "@/modules/genprox/modules/profile/store/types";
import {Prop} from "vue-property-decorator";
import {Investor, Product} from "@/modules/genprox/modules/fund/modules/capital-rise/store/types";
import ProfileProductsTable
  from "@/modules/genprox/modules/profile/components/profile-dashboard/ProfileProductsTable.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniRectButton from "@/components/buttons/SygniRectButton.vue";
import ProfileInvestorMainContent
  from "@/modules/genprox/modules/profile/containers/profile-investor/ProfileInvestorMainContent.vue";
import {Contexts, UserContext} from "@/modules/genprox/models/User";

@Component({
  components: {
    ProfileInvestorMainContent,
    SygniRoundedButton,
    SygniRectButton,
    ProfileProductsTable,
    ProfileSideBar,
    ProfileInvestorsDashboard,
    ProfileTransactionHistoryTable,
    SygniContainerTitle, ProfilePresentation, SygniSocialsButton, UserPresentation, SygniCard},
  computed: {
    ...mapState('profile', {
      activeUserProfile: (state: any) => state.profile,
      activeUserBusinessLinks: (state: any) => state.businessLinks
    }),
    ...mapState('genprox', {
      activeUserContext: (state: any) => state.activeUserData.context,
    }),
  }
})
export default class ProfileDashboardContainer extends Vue {

  activeUserProfile!: ProfileDataState;
  activeUserBusinessLinks!: Array<BusinessLink>;
  activeUserContext!: UserContext;
  readonly contexts = Contexts;

  @Prop() investorProductsByGuest!: Array<Product>;
  @Prop() viewType!: 'guest' | 'owner' | 'investor';

  showMoreAbout: boolean = true;

  get investorId(): string {
    if (this.viewType === 'investor') {
      return (this.$route.params.id as unknown as string);
    }
    return '';
  }

  get profile(): ProfileDataState {
    if (this.viewType === 'guest') {
      return (this.$route.params.profileByGuest as unknown as ProfileDataState);
    } else if (this.viewType === 'investor') {
      return (this.$route.params.investorProfile as unknown as ProfileDataState & Investor);
    } else if (this.viewType === 'owner'){
      return this.activeUserProfile;
    }
    return null;
  }
  get businessLinks(): Array<BusinessLink> {
    if (this.viewType === 'guest' || this.viewType === 'investor') {
      return (this.$route.params.businesssLinksByGuest as any);
    } else if (this.viewType === 'owner'){
      return this.activeUserBusinessLinks;
    }
    return null;
  }

  async mounted() {
    if(this.viewType === 'owner') {
      await Promise.all([
          this.$store.dispatch('profile/getWalletProductsForLoggedUser'),
          this.$store.dispatch('profile/getKeyKPIsForLoggedUser'),
          this.$store.dispatch('profile/getBusinessLinks', true),
          this.$store.dispatch('profile/getActiveUserTransactionsHistory')
      ]);
    }
    if(this.profile?.generalData?.professionalDescription?.length > 200){
      this.showMoreAbout = false;
    }
  }

}
